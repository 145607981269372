var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c("showdialog", { attrs: { message: _vm.messages } }),
      _c("terms", { attrs: { message: _vm.messages } }),
      _c("showactionresult", { attrs: { message: _vm.messages } }),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            "clipped-left": "",
            tile: "",
            height: _vm.isMobile ? 48 : 60,
            color: "white",
            elevation: "1",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "primary--text",
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center ml-4",
                  attrs: { cols: "auto" },
                },
                [
                  _c("router-link", { attrs: { to: "/Account/Login" } }, [
                    _c("img", {
                      attrs: {
                        height: 20,
                        src: require("@/assets/logo.png"),
                        alt: "Logo",
                      },
                    }),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-flex align-center font-weight-bold primary--text caption",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.OpenHelpDocument()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("mdi-lifebuoy"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("UserGuide")) + " "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-flex align-center font-weight-bold primary--text caption",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.ChangeLanguage()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("mdi-earth"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("ChangeLanguageText")) + " "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("v-divider", { attrs: { vertical: "" } })],
                1
              ),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("span", { staticClass: "font-weight-medium title" }, [
                  _vm._v(_vm._s(_vm.$t("SupplierPortal"))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            app: "",
            clipped: "",
            "mobile-breakpoint": "sm",
            "disable-resize-watcher": "",
            "disable-route-watcher": "",
            color: "primary",
            width: "280",
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            text: "",
                            block: "",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.Logout()
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("AL_Logout")))]),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("exit_to_app"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mt-5 text-center" },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass:
                        "primary--text white display-1 font-weight-bold mb-4",
                      attrs: { size: "64" },
                    },
                    [_vm._v(" " + _vm._s(_vm.GetUserGorevInitials) + " ")]
                  ),
                  _c("p", { staticClass: "title my-0 white--text" }, [
                    _vm._v(" " + _vm._s(_vm.GetUserName) + " "),
                  ]),
                  _c("p", { staticClass: "caption mb-0 white--text" }, [
                    _vm._v(" " + _vm._s(_vm.GetUserGorev) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("accordionmenu", {
            attrs: { form: _vm.form },
            on: {
              menuclicked: function ($event) {
                return _vm.MenuClicked($event)
              },
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }